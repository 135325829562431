/* eslint-disable prettier/prettier */
import React, { lazy } from 'react';
import {KeepAliveTransfer} from 'keepAlive';
import {getLocalStorage} from 'utils/publicUtils';
const Login = lazy(() => import('view/login'));
const Layout = lazy(() => import('view/layout'));
const Order = lazy(() => import('view/layout/order'));
const Dashboard = lazy(() => import('view/layout/dashboard'));
const Inventory = lazy(() => import('view/layout/inventory'));
const CreateProduct = lazy(() => import('view/layout/inventory/createProduct'));
const ImportProducts = lazy(() => import('view/layout/importProducts'));
const ProductDetails = lazy(() => import('view/layout/inventory/productDetails'));
const Receiving = lazy(() => import('view/layout/receiving'));
const CreateReceiving = lazy(() => import('view/layout/receiving/createReceiving'));
const ReceivingDetails = lazy(() => import('view/layout/receiving/receivingDetails'));
const OrderDetails = lazy(() => import('view/layout/order/orderDetails'));
const CreateOrder = lazy(() => import('view/layout/order/createOrder'));
const Calculator = lazy(() => import('view/layout/calculator'));
const Users = lazy(() => import('view/layout/settings/users'));
const CreateUser = lazy(() => import('view/layout/settings/users/operate/createUser'));
const Roles = lazy(() => import('view/layout/settings/users/roles'));
const CreateRole = lazy(() => import('view/layout/settings/users/roles/operate/createRole'));
const Store = lazy(() => import('view/layout/settings/store'))
const StoreDetail = lazy(() => import('view/layout/settings/store/detail'))
const StoreCreate = lazy(() => import('view/layout/settings/store/create'))
const ShopifyAuthconfirm = lazy(() => import('view/layout/settings/store/shopify/authconfirm'))
const WooCommerceAuthconfirm = lazy(() => import('view/layout/settings/store/wooCommerce/authconfirm'))
const StoreCreateMapping = lazy(() => import('view/layout/settings/store/detail/storeCreateMapping'))
const PersonalSettings = lazy(() => import('view/layout/settings/personal'));
const Billing = lazy(() => import('view/layout/settings/billing'));
const Payment = lazy(() => import('view/layout/settings/billing/payment'));
const BillingDetails = lazy(() => import('view/layout/settings/billing/billingDetails'));
const PaymentTip = lazy(() => import('view/layout/settings/billing/payment/paymentTip'));
const Install = lazy(() => import('view/layout/thirdParty/shopify/install'));
const ErrorBody = lazy(() => import('view/layout/errorBody'));
const Audit = lazy(() => import('view/layout/other/audit'));
const StoreMapping = KeepAliveTransfer(StoreCreateMapping, 'storeMapping');
const OrderCreate = KeepAliveTransfer(CreateOrder, 'createOrder');
const CreateReceivingFer = KeepAliveTransfer(CreateReceiving, 'createReceiving');
const CreateInventory = KeepAliveTransfer(CreateProduct, 'CreateProduct');
const token = getLocalStorage('token')
const routes = [
  {
    path: '',
    element: token ? <Layout /> : <Login />,
    children: [
      { path: '', element: token? <Dashboard />:null },
    ]
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/importProducts/:type',
    element: <Layout />,
    children: [
      { path: '', element: <ImportProducts /> },
    ]
  },
  {
    path: '/dashboard',
    element: <Layout />,
    children: [
      { path: '', element: <Dashboard /> },
    ]
  },
  {
    path: '/order',
    element: <Layout />,
    children: [
      { path: '', element: <Order /> },
      { path: 'orderDetails/:id', element: <OrderDetails /> },
      { path: 'createOrder', element: <OrderCreate /> },
    ]
  },
  {
    path: '/inventory',
    element: <Layout />,
    children: [
      { path: '', element: <Inventory /> },
      { path: 'createProduct', element: <CreateInventory /> },
      { path: 'productDetails/:id', element: <ProductDetails /> },
    ]
  },
  {
    path: '/receiving',
    element: <Layout />,
    children: [
      { path: '', element: <Receiving /> },
      { path: 'createReceiving', element: <CreateReceivingFer /> },
      { path: 'receivingDetails/:id', element: <ReceivingDetails /> },
    ]
  },
  {
    path: '/calculator',
    element: <Layout />,
    children: [
      { path: '', element: <Calculator /> },
    ]
  },
  {
    path: '/store',
    element: <Layout />,
    children: [
      { path: '', element: <Store /> },
      { path: 'create', element: <StoreCreate /> },
      { path: 'shopify/authconfirm', element: <ShopifyAuthconfirm /> },
      { path: 'wooCommerce/authconfirm', element: <WooCommerceAuthconfirm /> },
      { path: ':id', element: <StoreDetail /> },
      { path: '/store/storeCreateMapping', element: <StoreMapping /> },
    ]
  },
  {
    path: '/users',
    element: <Layout />,
    children: [
      { path: '', element: <Users /> },
      { path: 'createUser', element: <CreateUser /> },
    ]
  },
  {
    path: '/settings',
    element: <Layout />,
    children: [
      { path: 'personal', element: <PersonalSettings /> },
    ]
  },
  {
    path: '/roles',
    element: <Layout />,
    children: [
      { path: '', element: <Roles /> },
      { path: 'createRole', element: <CreateRole /> },
    ]
  },
  {
    path: '/billing',
    element: <Layout />,
    children: [
      { path: '', element: <Billing /> },
      { path: 'payment', element: <Payment /> },
      { path: 'billingDetails', element: <BillingDetails /> },
      { path: 'paymentTip', element: <PaymentTip /> },
    ]
  },
  {
    path: '/thirdParty',
    element: <Layout />,
    children: [
      { path: 'shopify/install',element: <Install />}
    ]
  },
  {
    path: '*',
    redirect: true,
  },
  {
    path: '/errorBody',
    element: <ErrorBody />,
  },
  {
    path: '/stockunits/audit',
    element: <Audit />,
  },
];
export default routes;
