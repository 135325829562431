/* eslint-disable prettier/prettier */
/**
 * api接口集合
 */
// eslint-disable-next-line no-undef
export const imageUrl = `${process.env.REACT_APP_END_POINT}/files/`; //图片文件
export const create = '/tokens/create'; //获取token
export const refresh = '/tokens/refresh'; //刷新token
export const emailResetUrl = '/accountEmployees/SendResetSecretLink'; //发送重置邮箱链接
export const checkResetPass = '/accountEmployees/CheckResetSecretLink'; //验证修改密码
export const modifyPass = '/accountEmployees/ChangeSecretByHash'; //修改密码
export const invientoryQuery = '/stockunits/query'; //Inventory 数据列表
export const warehouses = '/warehouses'; //仓库列表
export const createProduct = '/stockunits'; //产品相关
export const exportProduct = '/stockunits/export'; //导出产品
export const importpreview = '/commons/importpreview'; //导入产品
export const productDetails = '/stockunits/'; //查询产品详情、库存
export const potentialAccounts = '/potentialAccounts/create'; //创建客户
export const queryMapping = '/salesproducts/query'; //产品详情 Mapping、搜索
export const salesproducts = '/salesproducts'; //产品详情，更新、创建销售产品
export const productOrders = '/outboundOrders/query'; //产品详情订单、搜索
export const receivingQuery = '/inboundorders/query'; // 产品详情 receiving
export const outboundOrders = '/outboundOrders'; //订单详情、创建订单
export const stockUnitsWhsPreview = '/outboundOrders/{0}/stockUnitsWhsPreview'; //订单产品库存在仓库
export const createInboundorders = '/inboundorders/{0}'; //创建 receiving
export const tracking = '/inboundorders/{0}/tracking'; //receiving tracking
export const dowLabel = '/inboundorders/{0}/label'; //下载 label
export const receivingStatu = '/inboundOrders/statusOverview'; //receivingexport const status 汇总
export const shippingProducts = '/commons/shippingProducts'; //物流产品信息
export const briefs = '/quotations/briefs'; //物流方式
export const userListData = '/accountEmployees/query'; //用户列表
export const createUser = '/accountEmployees/create'; //创建用户
export const roleListData = '/roles/query'; //角色列表
export const userInfo = '/accountEmployees/{0}'; //用户详情
export const disableUser = '/accountEmployees/{0}/disable'; //禁用用户
export const enableUser = '/accountEmployees/{0}/enable'; //启用用户
export const deleteUser = '/accountEmployees/{0}/delete'; //删除用户
export const getCurrentuser = '/users/current'; //获取当前用户
export const changeUserContact = '/accountEmployees/{0}/changeContact'; //修改用户联系信息
export const inboundordersShipping = '/inboundorders/{0}/shipping';
export const changeSecret = '/accountEmployees/changeSecret'; //修改密码
export const updateUser = '/accountEmployees/{0}/update'; //编辑用户
export const permissionListData = '/permissions/query'; //权限列表
export const updateRole = '/roles/{0}/update'; //更新角色
export const createRole = '/roles/create'; //创建角色
export const disableRole = '/roles/{0}/disable'; //禁用角色
export const enableRole = '/roles/{0}/enable'; //启用角色
export const roleInfo = '/roles/{0}'; //角色详情
export const deleteRole = '/roles/{0}/delete'; //删除角色
export const storeQuery = '/stores/query'; //店铺列表
export const storeDetail = '/stores/'; //店铺详情
export const createStore = '/stores'; //创建店铺
export const saleProducts = '/stores/{0}/saleProducts'; // 店铺内的销售产品
export const importFromStorePreview = '/stockunits/importFromStorePreview'; // 店铺导入商品预览
export const storeOrderPreferencesFormat = '/stores/{0}/orderPreferences'; //店铺订单偏好
export const deleteProducts = '/stockunits/{0}/delete'; //删除产品
export const storeMarketplaceMetadataFormat = '/stores/{0}/marketplaceMetadata'; //店铺基本信息
export const shopifyStoreAuthCheck = '/stores/shopify/{0}/authCheck'; //shopify店铺授权前校验基本信息
export const shipmentShopify = '/stores/{0}/shopify'; //shopify 详情 合约授权
export const fulfillmentLocation = '/stores/{0}/shopify/fulfillmentLocation?locationId={1}&isFulfillment={2}'; //shopify 详情 是否授权
export const shopifyStoreAuthCheckForRedirect = '/stores/shopify/{0}/authCheckForRedirect'; //shopify店铺获取授权跳转地址
export const shopifyStoreAppUrlCheckForRedirect = '/stores/shopify/appUrlCheckForRedirect{0}'; //shopify授权跳转
export const shopifyStoreAuthConfirm = '/stores/shopify/{0}/AuthConfirm{1}'; //shopify店铺授权后的确认回调
export const dashboardsOrders = '/dashboards/orders';
export const dashboardsOutOfStock = '/dashboards/outOfStock';
export const dashboardsReceivings = '/dashboards/receivings';
export const dashboardsShippedOrders = '/dashboards/shippedOrders';
export const wooCommerceStoreAuthCheck = '/stores/woocommerce/authCheck?storeUrl={0}'; //woocommerce店铺授权前校验基本信息
export const wooCommerceStoreAuthCheckForRedirect = '/stores/woocommerce/authCheckForRedirect?storeUrl={0}'; //woocommerce店铺获取授权跳转地址
export const wooCommerceStoreAuthConfirm = '/stores/{0}/woocommerce/AuthConfirm?apiKey={1}&apiSecret={2}'; //woocommerce店铺授权后的确认回调
export const wooCommerceStoreSecret = '/stores/{0}/woocommerce?apiKey={1}&apiSecret={2}&storeUrl={3}'; //woocommerce店铺设置 apiKey & apiSecret
export const storeShippingMapping = '/outboundOrderShippingRules'; //store 模块接口
export const financeAccounts = '/finance/accounts'; //客户的已有的所有币种的账号
export const financeWeekbills = '/finance/weekbills'; //获取账单列表
export const financeAccount = '/finance/AccountLog'; //资金变动几率
export const financeApplyRechargeSession = '/finance/ApplyRechargeSession'; //充值申请
export const financeQueryRecharge = '/finance/QueryRecharge'; //充值查询
export const financeQueryOutbound = '/finance/QueryOutbound'; //查询
export const financeSaveBillingAddress = '/finance/SaveBillingAddress'; //创建修改地址
export const financeQueryBillingAddress = '/finance/QueryBillingAddress'; //地址查询
export const uploadFiles = '/files'; //上传文件
export const manualPreview = '/outboundOrders/manual-import-preview'; //orderexport const importexport const manual import
export const inventoryPreview = '/salesproducts/import-preview'; //inventory importexport const mipping 预览
export const shopifyPreview = '/outboundOrders/shopify-import-preview'; //order import shopifyexport const import
export const manualOrderImport = 'templates/manual_order_import_template.xlsx'; //orderexport const manual 文件模版
export const inventoryProductImport = 'templates/inventory_import_products_template.xlsx'; //inventory product 文件模版
export const inventoryMippingImport = 'templates/salesproduct_import_template.xlsx'; //inventory mipping 文件模版
export const lowStockStockUnits = '/dashboards/lowStockStockUnits'; //低库存
export const storeTaxs = '/StoreTaxs'; //创建Tax
export const storeTaxsQuery = '/StoreTaxs/query'; //查询创建Tax
export const storeTaxsDelete = '/StoreTaxs/{0}/delete'; //删除Tax
export const storeTaxsEdit = '/StoreTaxs/{0}/fill'; //编辑Tax
export const outboundOrdersLogs = '/outboundOrders/{0}/logs'; //查询logs
export const alertsOverview = '/outboundOrders/alertsOverview'; //alerts 订单汇总
export const statusOverview = '/outboundOrders/statusOverview'; // status 订单汇总
export const verfitySign = '/stockunits/{0}/verfitySign'; //,产品审核前面检验
