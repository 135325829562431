/* eslint-disable prettier/prettier */
import axios, { AxiosResponse, AxiosInstance } from 'axios';
import { getOutLogin, intervalTime, setLocalStorage, getLocalStorage } from './publicUtils';
import { create, emailResetUrl, checkResetPass, modifyPass, potentialAccounts } from 'api';
import { t } from 'i18next';

/**
 * isGetRequest 不需要token拦截
 * isCarryToken 有token携带，没有则不携带
 */

let { isGetRequest = false, isCarryToken = false } = {};
/**
 * 网络请求
 */
const service: AxiosInstance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_END_POINT,
});

// 路由校验列表
const noIdentityURLList = [create, emailResetUrl, checkResetPass, modifyPass, potentialAccounts];
//请求拦截
service.interceptors.request.use((config: any) => {
  config.headers['Accept-Language'] = 'en';
  // 不需要校验路由直接返回数据
  if (noIdentityURLList.indexOf(config.url) != -1 || isGetRequest) {
    if (isCarryToken) {
      getLocalStorage('token') ? (config.headers.Authorization = `Bearer ${getLocalStorage('token')}`) : null;
    }
    return config;
  }
  if (!getLocalStorage('token')) {
    getOutLogin();
    return;
  }
  intervalTime();
  setLocalStorage([
    {
      setKey: 'getResponseTime',
      setContent: JSON.stringify(new Date().getTime() + 60 * 60 * 1000),
    },
  ]);
  config.headers.Authorization = `Bearer ${getLocalStorage('token')}`;
  return config;
});

//响应拦截
service.interceptors.response.use(
  (resp: AxiosResponse) => {
    return resp.data;
  },
  (error) => {
    const { response, message = t('networkError') } = error;
    if (response?.status == 401) {
      getOutLogin();
      return;
    }
    if (response?.status == 404) {
      return { Succeeded: false, status: 404 };
    }
    interface MyObject {
      [key: string]: string;
    }
    const networkErrMap: MyObject = {
      400: t('parameterErroe'),
      403: t('noPermission'),
      495: t('RequestError'),
      500: t('systemError'),
    };
    const erroeData = { Error: { Message: networkErrMap[response?.status] ?? message }, Succeeded: false };
    return erroeData;
  }
);
// 解除登录拦截限制
export const openLimitation = (isRequest: boolean, isCarry?: boolean) => {
  isGetRequest = isRequest;
  isCarryToken = isCarry ?? false;
  return new Promise((resolve) => {
    resolve(isGetRequest);
  });
};
// post请求
export const post = (url: string, data: object) => {
  return new Promise((resolve) => {
    service.post(url, data).then((res) => {
      resolve(res);
    });
  });
};
// get请求
export const get = (url: string) => {
  return new Promise((resolve) => {
    service.get(url).then((res) => {
      resolve(res);
    });
  });
};
// 下载文件
export const downloadFile = (url: string, params: object, name: string) => {
  return new Promise((resolve) => {
    service.post(url, params, { responseType: 'blob' }).then((res: any) => {
      const blob = new Blob([res]); //处理文档流
      const fileName = res.type == 'application/pdf' ? `${name}_label.pdf` : 'product.xlsx';
      const elink = document.createElement('a');
      elink.download = fileName;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
      resolve(res);
    });
  });
};
// 上传文件
export const uploadFile = (url: string, params: any) => {
  return new Promise((resolve) => {
    service.post(url, params, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res: any) => {
      resolve(res);
    });
  });
};
// 并发请求数量控制
export const limitRequest = (url: string, data: any, maxNum: number) => {
  return new Promise((resolve) => {
    // 定义数组记录每次请求的结果（无论成功失败）
    const results: any = [];
    let index = 0;
    let cnt = 0;
    // 发送请求
    async function request() {
      if (index === data.length) return;
      const i = index;
      const getData = data[i];
      index++;
      try {
        const response: any = await service.post(url, getData);
        results[i] = response.Succeeded ? response : { ...response, ...getData };
      } catch (err) {
        results[i] = err;
      } finally {
        cnt++;
        // 判断是否请求都完成 所有请求已完成
        if (cnt === data.length) {
          resolve(results);
        }
        request();
      }
    }
    // maxNum和urls.length取最小进行调用
    // 将异步的并发数控制在，同一时间请求times次
    const times = Math.min(maxNum, data.length);
    for (let i = 0; i < times; i++) {
      request();
    }
  });
};
