/* eslint-disable prettier/prettier */
import React from 'react';
import { Routes, useLocation } from 'react-router-dom';
import routes from './router';
import { getRoute } from './router/getRoute'; //导入工具函数 渲染路由
import { setLocalStorage, delLocalStorage } from 'utils/publicUtils';
import 'rsuite/dist/rsuite.min.css';
function App() {
  const location = useLocation();
  if (location.pathname != '/') {
    const noLocalStorage = ['/login', '/errorBody', '/stockunits/audit', '/thirdParty/shopify/install', '/store/shopify/authconfirm'];
    if (noLocalStorage.indexOf(location.pathname) == -1) {
      setLocalStorage([{ setKey: 'roterUrl', setContent: JSON.stringify(location) }]);
    }
  } else {
    delLocalStorage(['layouRootIndex', 'layouTwoLevelIndex']);
    setLocalStorage([
      { setKey: 'leftNavKey', setContent: '/dashboard' },
      { setKey: 'roterUrl', setContent: JSON.stringify(location) },
    ]);
  }
  if (history.length === 1) {
    delLocalStorage(['roterUrl']); //历史记录被清除
  }
  return (
    <div className='App'>
      <Routes>{getRoute(routes)}</Routes>
    </div>
  );
}

export default App;
