/* eslint-disable prettier/prettier */
import * as actionTypes from './types';
/**
 *
 * @param {*} state keepAliveStates
 * @param {*} action { type, payload }
 */
export function KeepAliveReducer(state, action) {
  const { type, payload } = action;
  const { keepAliveId, reactElement, nodes } = payload;
  switch (type) {
    case actionTypes.CREATING:
      return {
        ...state,
        [keepAliveId]: {
          keepAliveId,
          reactElement,
          status: type,
          nodes: null,
        },
      };
    case actionTypes.CREATED:
      return {
        ...state,
        [keepAliveId]: {
          ...state[keepAliveId],
          status: type,
          nodes,
        },
      };
    case actionTypes.DESTROY:
      return {};
    default:
      return state;
  }
}
