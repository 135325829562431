/* eslint-disable prettier/prettier */
import lodash from 'lodash';
import { get } from 'utils/request';
import { refresh } from 'api';
import * as actionTypes from 'keepAlive/types';
/**
 * 本地存储
 * @param setStorage
 * setKey 	存储KEY
 * 		tokenTime 								token过期时间
 * 		token 										用户token
 * 		permissions 							权限
 * 		roterUrl  								登录后默认的显示的路由
 * 		currentUser								当前用户信息
 * 		getResponseTime 					用户操作停留的时间
 * 		layouRootIndex						一级组件导航栏位置下标
 * 		layouTwoLevelIndex 				二级组件导航栏位置下标
 * 		leftNavKey								右侧导航高亮位置
 * 		leftNavData								右侧导航顺序
 * 		inventoryManageColumn  		inventory列表顺序
 * 		orderManageColumn 				order列表顺序
 * 		receivingManageColumn 		receiving列表顺序
 * 		inventoryDetailReceiving  inventory 详情 Receiving 列表顺序
 * 		inventoryDetailOrder			inventory 详情 Order列表顺序
 * 		shopifyAuthConfirm        shopify 授权跳转回来的参数
 *    guideIndex                指示操作到第几步
 *    guideChilderSelKeyArr     导航栏指引进度
 * setContent 存储数据
 */
export const setLocalStorage = (setStorage: string | any[]) => {
  for (let i = 0; i < setStorage.length; i++) {
    const { setKey, setContent } = setStorage[i];
    localStorage.setItem(setKey, setContent);
  }
};
// 获取存储数据
export const getLocalStorage = (setKey: string) => {
  return localStorage.getItem(setKey);
};
// 清除存储数据
export const delLocalStorage = (delStorage: string[]) => {
  for (let i = 0; i < delStorage.length; i++) {
    localStorage.removeItem(delStorage[i]);
  }
};
// 退出登录
export const getOutLogin = () => {
  delLocalStorage(['tokenTime', 'token', 'currentUser', 'permissions', 'getResponseTime']);
  window.location.href = '/login';
};
// 刷新token
let isRefResh = false;
export const refreshToken = () => {
  const curTime = Math.round(new Date().getTime() / 1000);
  const tokenTime = Number(getLocalStorage('tokenTime'));
  if (curTime >= tokenTime && getLocalStorage('token') && !isRefResh) {
    isRefResh = true;
    get(refresh).then((res: any) => {
      const { Data = {}, Succeeded = false } = res;
      isRefResh = false;
      if (Succeeded) {
        setLocalStorage([
          { setKey: 'tokenTime', setContent: JSON.stringify(Data.Expire - 30 * 60) },
          { setKey: 'token', setContent: Data.Token },
          { setKey: 'permissions', setContent: JSON.stringify(Data) },
        ]);
      }
    });
  }
};
// 无操作停留时间20分钟则退出
export const intervalTime = () => {
  const getResponseTime = getLocalStorage('getResponseTime');
  if (getResponseTime && new Date().getTime() >= Number(getResponseTime)) {
    getOutLogin();
  } else {
    refreshToken();
  }
};
// 返回用户权限
export const permissions = (userPermissions: string) => {
  const { IsAdmin = false, Permissions = [] } = eval('(' + getLocalStorage('permissions') + ')') ?? {};
  return IsAdmin ? true : Permissions.includes(userPermissions);
};
// 时间格式
/**
 *
 * @param fmt   时间格式  yyyy/MM/dd 或y-m-d   hh:mm:ss可选时分秒
 * @param timeData  时间戳
 * @returns
 */
export const timeUtil = (fmt: string, timeData: any, custom?: boolean) => {
  const times = new Date(timeData);
  const getHours =  times.getHours()
  const getMinutes = times.getMinutes()
  const getSeconds = times.getSeconds()
  const o: any = {
    'M+': times.getMonth() + 1, //月份
    'd+': times.getDate(), //日
    'h+': getHours>=10?getHours:'0'+getHours, //小时
    'm+': getMinutes>=10?getMinutes:'0'+getMinutes, //分
    's+': getSeconds>=10?getSeconds:'0'+getSeconds, //秒
    'q+': Math.floor((times.getMonth() + 3) / 3), // 季度
    S: times.getMilliseconds(), // 毫秒
  };
  // 一月 		Jan；二月 		Feb；三月份 	Mar；四月份 	Apr；五月份 	May；六月份 	Jun；
  // 七月份 	Jul；八月份 	Aug；九月份 	Sept；十月 		Oct；十一月 	Nov；十二月 	Dec。
  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  // 根据y的长度来截取年
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (times.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  const timeH = fmt.includes('hh');
  const timeS = fmt.includes('ss');
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = custom
        ? fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
        : o['d+'] + ' ' + monthArr[o['M+'] - 1] + ',' + times.getFullYear() + ' ' + (timeH ? (timeH ? o['h+'] + ':' + o['m+'] : timeS ? o['s+'] : '') : '');
    }
  }

  return fmt;
};
// 长度+随机数
export const numberUitl = (num: any, length: number) => {
  for (let len = (num + '').length; len < length; len = num.length) {
    num = '0' + num;
  }
  return num;
};
// 防抖 输入
export const debounce = lodash.debounce;
// 节流 点击
export const throttle = lodash.throttle;
//替换字符串中的占位符
export const replaceFormat = (str: string, ...params: any[]) => {
  for (let i = 0; i <= params.length; i++) {
    str = str.replace(`{${i}}`, params[i]);
  }
  return str;
};
/**
 * 将文字写入剪切板 复制
 * @param {string} text
 * @returns {Promise} 返回promise对象
 */
export const copyText = (text: string) => {
  // 在调用前 先访问是否存在 clipboard 对象
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    // 不存在使用 老版本API execCommand
    try {
      const t = document.createElement('textarea');
      t.nodeValue = text;
      t.value = text;
      document.body.appendChild(t);
      t.select();
      document.execCommand('copy');
      document.body.removeChild(t);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }
};
/**
 * 取数，如果是整数则用整数，如果不是整数则根据传入保留几位
 */
export const toFixedNumber = (num: any, fixedIndex: any) => {
  if (/^\d+$/.test(num)) {
    return num;
  } else {
    return num.toFixed(fixedIndex);
  }
};
/**
 * 获取指定日期时间
 * @param type 0 是当天 负数则是未来，正数则是以前
 * @returns
 */
export const getXmonthToday = (type: number) => {
  const data = new Date();
  const newDate: any = new Date(data.getTime() - type * 1000 * 60 * 60 * 24);
  const year = newDate.getFullYear();
  const month: any = parseInt(newDate.getMonth()) + 1 > 9 ? parseInt(newDate.getMonth()) + 1 : '0' + (parseInt(newDate.getMonth()) + 1);
  const day = newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate();
  const fullDate = `${year}-${month}-${day}`;
  return fullDate;
};
/**
 *
 * @param keepAliveId 清除缓存的keepAliveId
 */
export const clearKeepAliveCache = (keepAliveId: string, keepAliveStates: any, dispatch: any) => {
  if (keepAliveStates && dispatch) {
    for (const key in keepAliveStates) {
      if (key === keepAliveId) {
        dispatch({ type: actionTypes.DESTROY, payload: { keepAliveId: key } });
      }
    }
  }
};

/**
 *
 * @returns
 * Metric 	公制单位
 * Imperial 英制单位
 * small boolean true小写 false 大写
 */
export const scaleSystem = (small: boolean) => {
  let { length = '', weight = '' } = {};
  const { MeasurementSystem = 'Metric' } = eval('(' + getLocalStorage('currentUser') + ')') ?? {};
  length = MeasurementSystem == 'Metric' ? 'cm' : 'in';
  weight = MeasurementSystem == 'Metric' ? 'g' : 'lb';
  length = small ? length.toLowerCase() : length.toUpperCase();
  weight = small ? weight.toLowerCase() : weight.toUpperCase();
  return { length, weight };
};
