/* eslint-disable prettier/prettier */
import { useCallback, useReducer, useContext, useState } from 'react';
import { Loader } from 'rsuite';
import { KeepAliveReducer } from './keepAliveReducer';
import * as actionTypes from './types';
import { KeepAliveContext } from './keepAliveContext';
import Toaster from 'utils/toaster';
function KeepAlive(props) {
  const [keepAliveStates, dispatch] = useReducer(KeepAliveReducer, {});
  const [toasterData, setToasterData] = useState({});
  const [loader, setLoader] = useState(false);
  const setKeepAliveState = useCallback(
    ({ reactElement, keepAliveId }) => {
      if (!keepAliveStates[keepAliveId]) {
        dispatch({
          type: actionTypes.CREATING,
          payload: {
            keepAliveId,
            reactElement,
          },
        });
      } else {
        let cacheState = keepAliveStates[keepAliveId];
        if (cacheState.status === actionTypes.DESTROY) {
          let doms = cacheState.doms;
          doms.forEach((dom) => dom.parentNode.removeChild(dom));
          dispatch({ type: actionTypes.CREATING, payload: { keepAliveId, reactElement } });
        }
      }
    },
    [keepAliveStates]
  );
  // 提示框，缓存组件可共享
  const showToaster = (text, type) => {
    setToasterData({
      text: text,
      type: type,
    });
    setTimeout(() => {
      setToasterData({});
    });
  };
  // 加载框 缓存组件可共享
  const showLoder = (statu) => {
    setLoader(statu);
    setTimeout(() => {
      if (loader) {
        setLoader(false);
        showToaster('timeout', 'error');
      }
    }, 6000);
  };
  return (
    <>
      <KeepAliveContext.Provider
        value={{
          keepAliveStates,
          setKeepAliveState,
          dispatch,
          showToaster,
          showLoder,
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {props.children}
        {Object.values(keepAliveStates).map(({ keepAliveId, reactElement }) => (
          <div
            key={keepAliveId}
            ref={(node) => {
              if (node && !keepAliveStates[keepAliveId].nodes) {
                dispatch({
                  type: actionTypes.CREATED,
                  payload: {
                    keepAliveId,
                    nodes: [...node.childNodes],
                  },
                });
              }
            }}>
            {reactElement}
          </div>
        ))}
      </KeepAliveContext.Provider>
      <Toaster data={toasterData} />
      {loader ? <Loader size='md' center backdrop={true} style={{ zIndex: '11' }} /> : null}
    </>
  );
}
const useCacheContext = () => {
  const context = useContext(KeepAliveContext);
  if (!context) {
    throw new Error('useCacheContext必须在Provider中使用');
  }
  return context;
};
export { KeepAlive, useCacheContext };
