/* eslint-disable prettier/prettier */
import { Suspense } from 'react';
import { Navigate, Route} from 'react-router-dom';
export const getRoute = (routes: any[]) => {
  return routes.map(item => {
    return  <Route path={item.path} key={item.path} element={item.redirect? <Navigate to="/errorBody"  /> : 
              <Suspense fallback={<div></div>}>
                {item.element}
              </Suspense>}>
              {
                item.children && item.children.length ? getRoute(item.children) : ''
              }
            </Route>
  })
}