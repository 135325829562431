/* eslint-disable prettier/prettier */
import React, { useEffect} from 'react';
import { useToaster,Message} from 'rsuite';
function toaster (params:any) {
  const {text,type} = params.data
  const toaster = useToaster();
  useEffect(()=>{
    text?toaster.push(
    <Message type={type}  className={`toasterMessageBox ${type=='success'?'successMessage':'errorMessage'}`}>
      <img src={type=='success'?require('images/successIcon.png'):require('images/warn.png')} className='toasterMessageIcon'/>
      <p className='toasterMessageTxt'>{text}</p>
    </Message>, {
      placement: 'topEnd',
      duration:4000,
    }):null;
  },[params])
  return (
    <></>
  )
}
export default toaster