/* eslint-disable prettier/prettier */
/* eslint-disable react/display-name */
import { useContext, useEffect, useRef } from 'react';
import { KeepAliveContext } from './keepAliveContext';
export function KeepAliveTransfer(KeepAliveComponent, keepAliveId) {
  return function (props) {
    const _ref = useRef(null);
    const { keepAliveStates, setKeepAliveState } = useContext(KeepAliveContext);
    useEffect(() => {
      const state = keepAliveStates[keepAliveId];
      if (state && state.nodes) {
        state.nodes.forEach((node) => _ref.current.appendChild(node));
      } else {
        setKeepAliveState({
          reactElement: <KeepAliveComponent {...props} />,
          keepAliveId,
        });
      }
    }, [keepAliveStates, setKeepAliveState, props]);
    return <div className='layoutRight' ref={_ref}></div>;
  };
}
